import React, { createContext, useState } from "react";

const OrderContext = createContext();
export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  return (
    <OrderContext.Provider value={[orders, setOrders]}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
