// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-beers-jsx": () => import("./../../../src/pages/beers.jsx" /* webpackChunkName: "component---src-pages-beers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-jsx": () => import("./../../../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-pizzas-jsx": () => import("./../../../src/pages/pizzas.jsx" /* webpackChunkName: "component---src-pages-pizzas-jsx" */),
  "component---src-pages-slicekings-jsx": () => import("./../../../src/pages/slicekings.jsx" /* webpackChunkName: "component---src-pages-slicekings-jsx" */),
  "component---src-templates-pizza-jsx": () => import("./../../../src/templates/Pizza.jsx" /* webpackChunkName: "component---src-templates-pizza-jsx" */),
  "component---src-templates-slice-king-jsx": () => import("./../../../src/templates/SliceKing.jsx" /* webpackChunkName: "component---src-templates-slice-king-jsx" */)
}

